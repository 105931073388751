import {
  ActionFunction,
  json,
  LoaderFunctionArgs,
  MetaFunction,
  redirect,
} from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { JobBoard } from "./resources/jobboard";
import { fetchJobBoardData } from "~/utils/fetchJobBoardData";
import { HeaderCTA } from "~/components/HeaderCTA";
import { headerCTAAction } from "~/utils/headerCTAAction";
import { getSession } from "~/sessions";
import { authenticate } from "~/utils/authenticate";
import { getServerTiming } from "~/utils/getServerTiming";

export { headers } from "~/defaults.server";

export const meta: MetaFunction = () => [
  {
    title: "€100k+ Software Engineering Jobs in Europe",
  },
  {
    name: "description",
    content: `Get notified about new opportunities at top tech companies.`,
  },
  {
    tagName: "link",
    rel: "canonical",
    href: `https://nextleveljobs.eu/`,
  },
];

export async function loader({ request }: LoaderFunctionArgs) {
  const { time, getServerTimingHeader } = getServerTiming();

  const session = await time("getSession", () =>
    getSession(request.headers.get("Cookie"))
  );
  const accessToken = session.get("accessToken");
  if (accessToken) {
    const subscription = await time("authenticate", () =>
      authenticate(request, false)
    );
    if (subscription.active) {
      return redirect("/jobs");
    }
  }

  const jobboardData = await time("fetchJobBoardData", () =>
    fetchJobBoardData({
      request,
      authenticated: false,
    })
  );

  return json(
    { jobboardData },
    {
      headers: getServerTimingHeader(),
    }
  );
}

export const action: ActionFunction = headerCTAAction;

export default function Landing() {
  const { jobboardData } = useLoaderData<typeof loader>();
  return (
    <div className="mx-auto w-full flex flex-grow flex-wrap flex-col ">
      <HeaderCTA />
      <JobBoard {...jobboardData} />
    </div>
  );
}
